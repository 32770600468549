// Blank L00K Template





// CSS
import '../styles/l00k__blank.scss';
import '../styles/l00k__arp.scss';
// JS


// Svg
// https://parceljs.org/languages/svg/#inlining-as-a-string
import huQrSvg from 'bundle-text:../svg/hu__qr.svg';





// Thing
// import play from './components/playTape';
import play from './components/biArp';


document.addEventListener('DOMContentLoaded', function () {

	// Inject QR Svg
	const qrSpans = document.querySelectorAll('.l00k__qrCode');
	qrSpans.forEach(function (qrSpan) {
		qrSpan.innerHTML = huQrSvg;
	});

	// L00K NAV
	var toggleLookNav = document.querySelectorAll('.toggle__l00k__nav');
	const mainLook = document.querySelectorAll('.l00k');

	toggleLookNav.forEach(function (toggleButton) {
		if (toggleButton instanceof HTMLButtonElement) {

			toggleButton.onclick = function (event) {

				mainLook.forEach(look => look.classList.toggle("w__l00k__nav"));

				if (toggleButton.getAttribute("aria-expanded") == "true") {
					toggleButton.setAttribute("aria-expanded", "false");
				} else {
					toggleButton.setAttribute("aria-expanded", "true");
				};

			};
		}
	});

	const main = document.querySelector('main');
    const toggleButton = document.querySelector('.toggle__l00k__nav');
    const isDesktop = window.innerWidth >= 1024;
    
    // Check if we came from one of our internal pages
    const referrer = document.referrer;
    const internalPages = ['index.html', 'a2.html', 'a3.html', 'a4.html', 'a5.html'];
    
    if (isDesktop && referrer) {
        const referrerPath = new URL(referrer).pathname;
        const isInternalNavigation = internalPages.some(page => referrerPath.endsWith(page));
        
        if (isInternalNavigation) {
            main.classList.add('w__l00k__nav');
            toggleButton.setAttribute('aria-expanded', 'true');
        }
    }

}, false);