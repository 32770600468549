import { gsap } from "gsap";
import { MorphSVGPlugin } from "gsap/MorphSVGPlugin";
import { CustomEase } from "gsap/CustomEase";
import { Draggable } from "gsap/Draggable";
import * as Tone from 'tone';

// Svg imports
import handSvg from 'bundle-text:../../svg/hand_c.svg';
import sliderSvg from 'bundle-text:../../svg/rangeSliderTwo.svg';
import fanSvg from 'bundle-text:../../svg/fan3.svg';
import '../../styles/components/hand.scss';

// Ready
document.addEventListener('DOMContentLoaded', function () {

    gsap.registerPlugin(CustomEase, MorphSVGPlugin, Draggable);

    // Inject SVGs for fan and slider elements
    const injectSvg = (selector, svgContent) => {
        document.querySelectorAll(selector).forEach(span => {
            span.innerHTML = svgContent;
        });
    };

    injectSvg('.fanSpans span', fanSvg);
    injectSvg('#slider span', sliderSvg);
    injectSvg('#hand span', handSvg);

    // Hand Animations
    gsap.set(".circlePalm", { transformOrigin: "center center", scale: 1.14 });
    gsap.set(".squarePalm", { transformOrigin: "center center", scale: 1.14, autoAlpha: 0 });
    gsap.set("#theHand", { transformOrigin: "center center", y: 10, autoAlpha: 0 });

    gsap.to("#theHand", { duration: 0.25, delay: 1, y: "-=15", autoAlpha: 1 });

    theHand.addEventListener('mouseenter', function () {
        // gsap.to(".circlePalm", { duration: 0.3, scale: "-=0.25", ease: CustomEase.create("custom", "M0,0,C0.083,0.294,0.061,1.077,0.438,1.136,0.736,1.182,0.826,1,1,1") });
        gsap.to(".squarePalm", { duration: 0.2, scale: 3, ease: CustomEase.create("custom", "M0,0,C0.083,0.294,0.061,1.077,0.438,1.136,0.736,1.182,0.826,1,1,1") });
        gsap.to("#theHand", { duration: 0.5, y: "-=5", ease: CustomEase.create("custom", "M0,0,C0.083,0.294,0.061,1.077,0.438,1.136,0.736,1.182,0.826,1,1,1") });
    });

    theHand.addEventListener('mouseleave', function () {
        // gsap.to(".circlePalm", { duration: 0.1, scale: "+=0.25",  });
        gsap.to("#FingersPalm", { duration: 0.1 });
        gsap.to(theHand, { duration: 0.3, y: "+=5", ease: CustomEase.create("custom", "M0,0,C0.083,0.294,0.061,1.077,0.438,1.136,0.736,1.182,0.826,1,1,1") });
    });

    // Sound Setup
    const merge = new Tone.Merge();
    const reverb = new Tone.Reverb({ wet: 1, decay: 11 });
    const filter = new Tone.Filter(2000, 'lowpass').toDestination();
    const feedbackDelay = new Tone.FeedbackDelay(1, 1).toDestination();
    merge.chain(reverb, Tone.Destination);
    merge.chain(filter, Tone.Destination);
    merge.chain(feedbackDelay, Tone.Destination);

    // Set up dot animations initially
    gsap.set(".--left .dot, .--right .dot", {
        scale: 0,
    });

    // Function to animate the dot scaling
    function animateDot(dotSelector) {
        gsap.fromTo(dotSelector, { scale: 0 }, { scale: 1, duration: 0.2, yoyo: true, repeat: 1, ease: "power1.inOut" });
    }

    // Sound Setup
    const synthL = new Tone.Synth({
        oscillator: { type: "triangle", modulationType: "sine", harmonicity: 1.01 },
        envelope: { attack: 0.25625, decay: 1, sustain: 0.25, release: 1.25 },
    }).toDestination();
    synthL.volume.value = -9; // Adding volume reduction in decibels

    const synthR = new Tone.Synth({
        oscillator: { type: "triangle", modulationType: "sine", harmonicity: 11 },
        envelope: { attack: 0.25625, decay: 1, sustain: 0.25, release: 1.25 },
    }).toDestination();
    synthR.volume.value = -9; // Adding volume reduction in decibels

    // Sequence for synthL with dot animation
    const partL = new Tone.Sequence((time, note) => {
        synthL.triggerAttackRelease(note, '4n', time);
        // Trigger the dot animation for synthL (".--left .dot")
        animateDot(".--left .dot");
    }, ["C2", "C2", "C2", "C2"]).start(0);

    // Sequence for synthR with dot animation
    const partR = new Tone.Sequence((time, note) => {
        synthR.triggerAttackRelease(note, '8n', time);
        // Trigger the dot animation for synthR (".--right .dot")
        animateDot(".--right .dot");
        // }, ["C2", "D2", "E2", "G2", "C2", "D2", "E2", "F2"]).start(0);
    }, ["G2", "G2", "G2", "G2"]).start(0);

    // Function to update fan animation based on playback speed
    function updateFanSpeed(fanTimeline, duration) {
        fanTimeline.duration(duration);
    }

    // Fan animation timelines (paused initially)
    const fanTimelineL = gsap.timeline({ repeat: -1, paused: true }).to("#left span svg #fan-movement", { rotate: "-=360", ease: "none" });
    const fanTimelineR = gsap.timeline({ repeat: -1, paused: true }).to("#right span svg #fan-movement", { rotate: "-=360", ease: "none" });

    // Slider logic to control playback speed, attack, decay, and release
    function createSlider(sliderSelector, sequence, handleSelector, fanTimeline, synth) {
        const minDuration = 0.5;  // Fastest (e.g., 0.5 seconds)
        const maxDuration = 7.75;  // Slowest (e.g., 7 seconds)
        const startDuration = (minDuration + maxDuration) / 2;  // Mid-point for duration

        const minDecay = 0.5;  // Minimum decay value when slider is to the right
        const maxDecay = 5;    // Maximum decay value when slider is to the left

        const minRelease = 11.25;  // Minimum release value when slider is to the right
        const maxRelease = 50;    // Maximum release value when slider is to the left

        const minAttack = 0.0125;  // Minimum attack value (slider to the right)
        const maxAttack = 0.5;     // Maximum attack value (slider to the left)
        const startAttack = (minAttack + maxAttack) / 2;  // Mid-point for attack

        // Set initial playback rate and fan speed to middle (50%)
        const initialPlaybackRate = 1 / startDuration;
        sequence.playbackRate = initialPlaybackRate;
        updateFanSpeed(fanTimeline, startDuration);

        // Set the initial values for attack, decay, and release at the midpoint
        synth.envelope.attack = startAttack;
        synth.envelope.decay = (minDecay + maxDecay) / 2;
        synth.envelope.release = (minRelease + maxRelease) / 2;

        Draggable.create(handleSelector, {
            type: "x",
            bounds: sliderSelector,
            edgeResistance: 1,
            throwProps: true,
            onDrag: function () {
                const percentage = this.x / this.maxX;

                // Map the percentage (0 to 1) to a range between minDuration and maxDuration
                const duration = maxDuration - percentage * (maxDuration - minDuration);

                // Set the sequence playback rate based on duration
                const playbackRate = 2 / duration;
                sequence.playbackRate = playbackRate;

                // Adjust the fan animation speed to reflect the new duration
                updateFanSpeed(fanTimeline, duration);

                // Adjust the synth decay, release, and attack based on the slider position
                if (percentage < 0.5) {
                    // If slider is less than 50%, increase decay, release, and attack
                    const scaledPercentage = percentage * 2;  // Scale for the left half
                    const decay = maxDecay - (scaledPercentage * (maxDecay - minDecay));
                    const release = maxRelease - (scaledPercentage * (maxRelease - minRelease));
                    const attack = maxAttack - (scaledPercentage * (maxAttack - minAttack));
                    synth.envelope.decay = decay;
                    synth.envelope.release = release;
                    synth.envelope.attack = attack;
                } else {
                    // If slider is more than 50%, reset decay, release, and attack
                    synth.envelope.decay = minDecay;
                    synth.envelope.release = minRelease;
                    synth.envelope.attack = minAttack;
                }
            },
        });

        // Start the slider handle at the middle (50%)
        const middleX = (document.querySelector(sliderSelector).offsetWidth - document.querySelector(handleSelector).offsetWidth) / 2;
        gsap.set(handleSelector, { x: middleX });

        // Attack: Ranges from 0.0125 (fast) to 0.5 (slower), with a midpoint of (0.0125 + 0.5) / 2 = 0.25625.
        // Decay: Ranges from 0.5 to 5, with a midpoint of (0.5 + 5) / 2 = 2.75.
        // Release: Ranges from 11.25 to 50, with a midpoint of (11.25 + 50) / 2 = 30.625.
    }


    gsap.set("#right span svg #fan-movement, #left span svg #fan-movement", {
        yoyo: true, repeat: 0, transformOrigin: "center",
        // ease: CustomEase.create("custom", "M0,0,C0.083,0.294,0.061,1.077,0.438,1.136,0.736,1.182,0.826,1,1,1")
    });

    // Create sliders for each fan and synth pair
    createSlider(".slider__l", partL, ".slider__l .HandleGroup", fanTimelineL, synthL);  // Left slider controlling partL and synthL
    createSlider(".slider__r", partR, ".slider__r .HandleGroup", fanTimelineR, synthR);  // Right slider controlling partR and synthR

    // Slider 
    gsap.set(".rangeSliderSvg", { transformOrigin: "center", autoAlpha: 0 });
    gsap.set(".slider__l .__Handle, .slider__r .__Handle", { transformOrigin: "center", pointerEvents: "none" });
    gsap.set(".slider__l .TrackGroup, .slider__r .TrackGroup", { transformOrigin: "center", scaleY: 1.25, scaleX: 0.875 });

    gsap.set(".slider__l g.TrackGroup, .slider__r g.TrackGroup", { transformOrigin: "center", autoAlpha: 0 });


    // Control Intro ( affordance signal )
    gsap.set("g.arrow-group line", { transformOrigin: "center", opacity: 0 });


    gsap.to("g.TrackGroup path, g.TrackGroup rect", {
        opacity: 1,
        transformOrigin: "center",
        delay: 2,
        duration: 0.75,
        ease: "power4.in",
    });

    // Set up a looping animation for pre-click effect
    const preClickAnimation = gsap.to(".circlePalm", {
        scale: "+=2.5",
        transformOrigin: "center",
        delay: 2,
        duration: 1.25,
        ease: "power4.in",
        repeat: -1, // Repeat indefinitely until clicked
        yoyo: true  // Creates a pulsing effect
    });

    // Shared state for active status
    let isActive = false;

    // Define the common function for handling the click event
    function handlePlayClick(event) {
        event.stopPropagation();

        // Toggle the shared active state
        isActive = !isActive;

        // Stop the pre-click animation on first click
        preClickAnimation.kill();

        if (isActive) {
            // Start the audio and fan animations
            Tone.start().then(() => {
                Tone.Transport.start();
                fanTimelineL.play();
                fanTimelineR.play();  // Start fan animations
            });

            gsap.to(".squarePalm", {
                duration: 0.125, scale: 2.5, autoAlpha: 1,
                ease: CustomEase.create("custom", "M0,0,C0.083,0.294,0.061,1.077,0.438,1.136,0.736,1.182,0.826,1,1,1")
            });
            gsap.set(".rangeSliderSvg", { transformOrigin: "center", autoAlpha: 1, delay: 0.5, duration: 1 });
            gsap.to(".circlePalm", { duration: 0.25, scale: 0, ease: CustomEase.create("custom", "M0,0,C0.083,0.294,0.061,1.077,0.438,1.136,0.736,1.182,0.826,1,1,1") });
            gsap.to(".slider__l g.TrackGroup, .slider__r g.TrackGroup", { delay: 2.5, duration: 0.25, autoAlpha: 1 });
            gsap.to(".slider__l .__Handle, .slider__r .__Handle", { pointerEvents: "all" });

            gsap.to("g.arrow-group line:nth-of-type(1), g.arrow-group line:nth-of-type(3)", {
                opacity: 1,
                transformOrigin: "center",
                yoyo: true,
                repeat: 1,
                repeatDelay: 0.125,
                strokeDashoffset: 10,
                duration: 1,
                delay: 0.5,
                ease: "power4.in",
            });
            gsap.to("g.arrow-group line:nth-of-type(2), g.arrow-group line:nth-of-type(4)", {
                opacity: 1,
                transformOrigin: "center",
                yoyo: true,
                repeat: 1,
                repeatDelay: 0.125,
                strokeDashoffset: 10,
                duration: 1.5,
                ease: "power4.in",
            });
            gsap.to(".--rangeSlider h2", { autoAlpha: 0, duration: 0.125, scale: 0.95, transformOrigin: "center" });
        } else {
            // Stop the audio and fan animations
            Tone.Transport.stop();
            fanTimelineL.pause();
            fanTimelineR.pause();  // Pause fan animations
            gsap.to("#right span svg #fan-movement, #left span svg #fan-movement", {
                rotate: "-=4", duration: 0.5,
                ease: CustomEase.create("custom", "M0,0,C0.083,0.294,0.061,1.077,0.438,1.136,0.736,1.182,0.826,1,1,1")
            });

            gsap.to(".squarePalm", { duration: 0.125, autoAlpha: 0, scale: 3, ease: CustomEase.create("custom", "M0,0,C0.083,0.294,0.061,1.077,0.438,1.136,0.736,1.182,0.826,1,1,1") });
            gsap.to(".circlePalm", { duration: 0.25, scale: 5, ease: CustomEase.create("custom", "M0,0,C0.083,0.294,0.061,1.077,0.438,1.136,0.736,1.182,0.826,1,1,1") });
            gsap.set("g.arrow-group line", { transformOrigin: "center", autoAlpha: 0 });
        }

        // Update the active class on all related elements
        document.querySelectorAll('.playThisHand, .hm__control.--rangeSlider > div.slider__r h2, .hm__control.--rangeSlider > div.slider__l h2')
            .forEach(el => el.classList.toggle("active", isActive));
    }

    // Attach the click event to `.playThisHand` elements
    document.querySelectorAll('.playThisHand').forEach(function (playButton) {
        playButton.addEventListener("click", handlePlayClick);
    });

    // Attach the click event to the new elements
    document.querySelectorAll('.hm__control.--rangeSlider > div.slider__r h2, .hm__control.--rangeSlider > div.slider__l h2')
        .forEach(function (button) {
            button.addEventListener("click", handlePlayClick);
        });



    // gsap.set(".__player.__hand #hand span svg#theHand", {
    //     transformOrigin: "center",
    // });

    // gsap.set(".--left .dot, .--right .dot", {
    //     scale: 0,
    // });

    // gsap.to(".--left .dot", {
    //     scale: 1,
    //     repeat: -1,
    //     yoyo: true,
    // });
    // gsap.to(".--right .dot", {
    //     scale: 1,
    //     repeat: -1,
    //     yoyo: true,
    // });

    Draggable.create(hand, {
        type: "x,y",
        edgeResistance: 2.2,
        throwProps: true,
        bounds: "article",
        allowEventDefault: true,
        onDrag: function () {

            gsap.to(".handLayer", {
                duration: 0.2,
                rotate: "-2deg",
                ease: CustomEase.create("custom", "M0,0,C0.083,0.294,0.061,1.077,0.438,1.136,0.736,1.182,0.826,1,1,1")
            });
        },
        onDragEnd: function () {
            gsap.to(".handLayer", {
                duration: 0.2,
                rotate: 0,
                ease: CustomEase.create("custom", "M0,0,C0.083,0.294,0.061,1.077,0.438,1.136,0.736,1.182,0.826,1,1,1")
            });
        },
    });



    // Gong one
    function playGongSound() {
        const gongSynth = new Tone.Synth({
            oscillator: {
                type: 'triangle'
            },
            envelope: {
                attack: 0.675,
                decay: 4,
                sustain: 0.5,
                release: 4
            }
        }).toDestination();
        gongSynth.volume.value = 2;

        // Trigger the gong sound on click event
        // document.addEventListener('click', function () {
        gongSynth.triggerAttackRelease("G#2", "11n");
        // });
    }
    // Gong Two 
    function initGongSoundTwo() {
        const gongSynthTwo = new Tone.Synth({
            oscillator: {
                type: 'triangle'
            },
            envelope: {
                attack: 1,
                decay: 10,
                sustain: 0.5,
                release: 4
            }
        }).toDestination();

        // Lower the volume of the synth
        gongSynthTwo.volume.value = -7; // Adjust the volume level as needed (-12 dB here)

        return gongSynthTwo;
    }

    // Initialize the synthesized gong sound
    const gongSynthTwo = initGongSoundTwo();

    // Variable to keep track of whether the gong sound is currently playing
    let isGongPlaying = false;

    let leftFan = document.getElementsByClassName("fan--left")[0];

    // Function to get random position within bounds as a percentage of container size
    function getRandomPosition(minPercent, maxPercent, containerSize) {
        const range = (maxPercent - minPercent) * containerSize / 100;
        const min = minPercent * containerSize / 100;
        return Math.random() * range + min;
    }

    Draggable.create(leftFan, {
        type: "x,y",
        edgeResistance: 0.75,
        throwProps: true,
        allowEventDefault: true,
        bounds: ".--left .__viz",

        onClick: function () {
            playGongSound();
            // gsap.to("#left span svg g ", {
            //     opacity: 0.5, duration: 0.5, repeat: 1, yoyo: true, transformOrigin: "center",
            //     ease: CustomEase.create("custom", "M0,0,C0.083,0.294,0.061,1.077,0.438,1.136,0.736,1.182,0.826,1,1,1")
            // });
            gsap.to("#left span svg path", {
                rotate: "-20deg", duration: 0.1, repeat: 1, yoyo: true, transformOrigin: "center",
                ease: CustomEase.create("custom", "M0,0,C0.083,0.294,0.061,1.077,0.438,1.136,0.736,1.182,0.826,1,1,1")
            });
        },


        onDragStart: function () {
            playGongSound();
            gsap.to("#left span svg path", {
                rotate: "-20deg", duration: 0.1, repeat: 1, yoyo: true, transformOrigin: "center",
                ease: CustomEase.create("custom", "M0,0,C0.083,0.294,0.061,1.077,0.438,1.136,0.736,1.182,0.826,1,1,1")
            });
        },

        onDrag: function () {
            gsap.to("#left span svg g ", {
                opacity: 0.5, duration: 0.125, transformOrigin: "center",
            });
            gsap.to("#left span svg g", {
                rotate: "-=360deg", duration: 0.1, repeat: 1, yoyo: true, transformOrigin: "center",
            });
            
            // Get container dimensions
            const container = document.querySelector('.--left .__viz');
            const containerWidth = container.offsetWidth;
            const containerHeight = container.offsetHeight;
            
            // Animate top dot with larger movement (±40% of container size)
            gsap.to(".--left .dot.--t", {
                x: getRandomPosition(-110, 70, containerWidth),
                y: getRandomPosition(-10, 40, containerHeight),
                duration: 14.4,
                ease: "none",
                overwrite: "none"
            });
            
            // Animate bottom dot with smaller, faster movement (±25% of container size)
            gsap.to(".--left .dot.--b", {
                x: getRandomPosition(-25, 115, containerWidth),
                y: getRandomPosition(-25, 25, containerHeight),
                duration: 15.2,
                ease: "none",
                overwrite: "none"
            });

            if (!isGongPlaying) {
                gongSynthTwo.triggerAttack("E3");
                isGongPlaying = true;
            }
        },
        onDragEnd: function () {
            gsap.to("#left span svg g ", {
                opacity: 1, duration: 0.125, transformOrigin: "center",
            });
            gsap.set("#left span svg path", {
                rotate: "0deg", duration: 0.1, transformOrigin: "center",
            });
            gongSynthTwo.triggerRelease();
            isGongPlaying = false;
            // Reset both dots with different timing
            gsap.to(".--left .dot.--t", {
                x: 0,
                y: 0,
                duration: 0.7,
                ease: "power2.out",
                overwrite: "none"
            });
            gsap.to(".--left .dot.--b", {
                x: 0,
                y: 0,
                duration: 0.4,
                ease: "power2.out",
                overwrite: "none"
            });
        },
    });

    let rightFan = document.getElementsByClassName("fan--right")[0];

    Draggable.create(rightFan, {
        type: "x,y",
        edgeResistance: 0.75,
        throwProps: true,
        allowEventDefault: true,
        bounds: ".--right .__viz",

        onClick: function () {
            playGongSound();
            // gsap.to("#left span svg g ", {
            //     opacity: 0.5, duration: 0.5, repeat: 1, yoyo: true, transformOrigin: "center",
            //     ease: CustomEase.create("custom", "M0,0,C0.083,0.294,0.061,1.077,0.438,1.136,0.736,1.182,0.826,1,1,1")
            // });
            gsap.to("#right span svg path", {
                rotate: "-20deg", duration: 0.1, repeat: 1, yoyo: true, transformOrigin: "center",
                ease: CustomEase.create("custom", "M0,0,C0.083,0.294,0.061,1.077,0.438,1.136,0.736,1.182,0.826,1,1,1")
            });
        },


        onDragStart: function () {
            playGongSound();
            gsap.to("#right span svg path", {
                rotate: "-20deg", duration: 0.1, repeat: 1, yoyo: true, transformOrigin: "center",
                ease: CustomEase.create("custom", "M0,0,C0.083,0.294,0.061,1.077,0.438,1.136,0.736,1.182,0.826,1,1,1")
            });
        },

        onDrag: function () {
            gsap.to("#right span svg g ", {
                opacity: 0.5, duration: 0.125, transformOrigin: "center",
            });
            gsap.to("#right span svg g", {
                rotate: "-=360deg", duration: 0.1, repeat: 1, yoyo: true, transformOrigin: "center",
            });
            
            // Get container dimensions
            const container = document.querySelector('.--right .__viz');
            const containerWidth = container.offsetWidth;
            const containerHeight = container.offsetHeight;
            
            // Animate top dot with larger movement (±40% of container size)
            gsap.to(".--right .dot.--t", {
                x: getRandomPosition(-20, 40, containerWidth),
                y: getRandomPosition(-40, 20, containerHeight),
                duration: 8.4,
                
                ease: "none",
                overwrite: "none"
            });
            
            // Animate bottom dot with smaller, faster movement (±25% of container size)
            gsap.to(".--right .dot.--b", {
                x: getRandomPosition(-45, 25, containerWidth),
                y: getRandomPosition(-25, 45, containerHeight),
                duration: 8.2,
                
                ease: "none",
                overwrite: "none"
            });

            if (!isGongPlaying) {
                gongSynthTwo.triggerAttack("e#3");
                isGongPlaying = true;
            }
        },
        onDragEnd: function () {
            gsap.to("#right span svg g ", {
                opacity: 1, duration: 0.125, transformOrigin: "center",
            });
            gsap.set("#right span svg path", {
                rotate: "0deg", duration: 0.1, transformOrigin: "center",
            });
            gongSynthTwo.triggerRelease();
            isGongPlaying = false;
            // Reset both dots with different timing
            gsap.to(".--right .dot.--t", {
                x: 0,
                y: 0,
                duration: 0.7,
                ease: "power2.out",
                overwrite: "none"
            });
            gsap.to(".--right .dot.--b", {
                x: 0,
                y: 0,
                duration: 0.4,
                ease: "power2.out",
                overwrite: "none"
            });
        },
    });

});
